<template>
  <div class="checkAccounts">
    <div class="searchBox">
        <div class="search-item">
          <div class="orderId mr20">
            <span class="mr10" style="line-height:35px;">订单号</span>
            <el-input v-model="orderId" style="width:180px;" clearable placeholder="请输入订单号"></el-input>
          </div>
          <div class="mr20">
            <div class="timeChoose">支付时间</div>
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-button type="primary" class="searchBtn" @click="search">筛选</el-button>
        </div>
        <PrevReclick rights="p_liquidation_list_export">
          <el-button type="primary" @click="exportExcel">导出</el-button>
        </PrevReclick>     
    </div>
      <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="spreadId" label="分公司ID" align="center"></el-table-column>
      <el-table-column prop="agentSid" label="代理商ID" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.agentSid == 0? '--' : scope.row.agentSid}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="staffId" label="员工编号" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.staffId?scope.row.staffId : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="shopId" label="商户ID" align="center"></el-table-column>
      <el-table-column prop="staffName" label="员工姓名" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.staffName?scope.row.staffName : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="shopCommissionRate" label="商户抽佣比例/%" align="center">
      <template slot-scope="scope">
          <span>{{scope.row.shopCommissionRate || scope.row.shopCommissionRate == 0?scope.row.shopCommissionRate + '%' : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="spreadCommissionRate" label="分公司抽佣比例" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.spreadCommissionRate || scope.row.spreadCommissionRate == 0?scope.row.spreadCommissionRate + '%' : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="spreadCommission" label="分公司分润金额" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.spreadCommission / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="agentCommissionRate" label="代理商抽佣比例" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.agentCommissionRate || scope.row.agentCommissionRate == 0?scope.row.agentCommissionRate + '%' : '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="agentCommission" label="代理商分润金额" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.agentCommission / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amountOfProfit" label="总抽佣金额" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.amountOfProfit / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="orderState" label="订单状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.orderState == 4">配送完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="订单创建时间" width="160">
        <template slot-scope="scope">
          <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payTime" label="支付时间" width="160">
        <template slot-scope="scope">
          <span>{{ $formatDate(scope.row.payTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payType" label="支付状态">
        <template slot-scope="scope">
          <span v-if="scope.row.payType == 1">未支付</span>
          <span v-if="scope.row.payType == 2">已支付</span>
          <span v-if="scope.row.payType == 3">已退款</span>
          <span v-if="scope.row.payType == 4">已关闭</span>
        </template>
      </el-table-column>
      <el-table-column prop="orderId" label="订单编号"></el-table-column>
      <el-table-column prop="tradeNo" label="支付订单编号" min-width="180" show-overflow-tooltip></el-table-column>
      <el-table-column prop="totalAmount" label="订单金额">
        <template slot-scope="scope">
          <span>{{scope.row.totalAmount / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="订单小计">
        <template slot-scope="scope">
          <span>{{scope.row.amount / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalPrice" label="实际支付金额">
        <template slot-scope="scope">
          <span>{{scope.row.totalPrice / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="distribution" label="用户配送费">
        <template slot-scope="scope">
          <span>{{scope.row.distribution / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="shopDistribution" label="商家配送费">
        <template slot-scope="scope">
          <span>{{scope.row.shopDistribution / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pakage" label="打包费">
        <template slot-scope="scope">
          <span>{{scope.row.pakage / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="allBearFee" label="手续费">
        <template slot-scope="scope">
          <span>{{scope.row.allBearFee / 100}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="distributionType" label="配送方式" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.distributionType == 1">平台配送</span>
          <span v-if="scope.row.distributionType == 2">商家自配送</span>
        </template>
      </el-table-column>
      <el-table-column prop="distributionPlatform" label="配送平台">
        <template slot-scope="scope">
          <span v-if="scope.row.distributionPlatform == 1">达达</span>
          <span v-if="scope.row.distributionPlatform == 2">蜂鸟</span>
          <span v-if="scope.row.distributionPlatform == 4">美团</span>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import { $sp_liquidationList, $sp_listExport } from '@/api/account';

export default {
  data() {
    return {
      orderId: '',
      time: '',
      tableData: [],
      totalPage: 1,
      currentPage: 1,
    };
  },
  created() {},
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 校验搜索数据
    checkSearchData() {
      // 如果orderId存在时间为非必填,否则时间为必填
      if (!this.orderId) {
        if (!this.time || this.time.length === 0) {
          this.$message.error('查询时间均不能为空');
          return false;
        }
        if (this.time[0].getFullYear() !== this.time[1].getFullYear() || this.time[0].getMonth() !== this.time[1].getMonth()) {
          this.$message.error('查询时间必须在同一个月内');
          return false;
        }
      } else if (this.time) {
        if (this.time[0].getFullYear() !== this.time[1].getFullYear() || this.time[0].getMonth() !== this.time[1].getMonth()) {
          this.$message.error('查询时间必须在同一个月内');
          return false;
        }
      }
      return true;
    },
    // 请求列表数据
    getTableData() {
      if (!this.checkSearchData()) return;
      const params = {
        orderId: this.orderId,
        pageCurrent: this.currentPage,
        pageSize: 14,
        payTimeStartStr: this.time ? `${this.$formatDateByDate(this.time[0], 'yyyy-MM-dd')} 00:00:00` : '',
        payTimeEndStr: this.time ? `${this.$formatDateByDate(this.time[1], 'yyyy-MM-dd')} 23:59:59` : '',
      };
      $sp_liquidationList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 导出
    exportExcel() {
      if (!this.checkSearchData()) return;
      const params = {
        orderId: this.orderId,
        payTimeStartStr: this.time ? `${this.$formatDateByDate(this.time[0], 'yyyy-MM-dd')} 00:00:00` : '',
        payTimeEndStr: this.time ? `${this.$formatDateByDate(this.time[1], 'yyyy-MM-dd')} 23:59:59` : '',
      };
      $sp_listExport(params).then((res) => {
        console.log(res);
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.checkAccounts{
  width: 100%;
    min-width: 1400px;
    height: 100%;
    margin: 0;
    padding: 40px 20px;
    box-sizing: border-box;
    background-color: #fff;
    .searchBox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search-item{
        display: flex;
        align-items: center;
        color: #666;
        font-size: 14px;
        .orderId{
          display: flex;
          & > div{
            width:70px;
            vertical-align: middle;
          }
        }
      }
      .searchBtn{
        margin-bottom: 4px;
      }
    }
}
  .el-form--inline .el-form-item{
    margin-right: 50px;
  }
  .inputStyle{
    width: 180px;
  }
  .timeChoose{
    width: 70px;
    text-align: left;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .tableBox{
    margin-top: 40px;
  }
  .el-table{
    min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
</style>
